import React, { Suspense, lazy } from 'react';
import { Html, ContactShadows, PresentationControls, Float, Environment, OrbitControls } from '@react-three/drei';

const Model = lazy(() => import('./components/Model'));
const TextComponent = lazy(() => import('./components/Text'));

export default function Experience() {
    return (
        <>
            <Environment preset='city' />
            <color args={['#241a1a']} attach="background" />
            <OrbitControls
                enableZoom={true}
                minAzimuthAngle={-Math.PI / 4} // Limit the horizontal rotation
                maxAzimuthAngle={Math.PI / 4}  // Limit the horizontal rotation
                minPolarAngle={Math.PI / 3}    // Limit the vertical rotation
                maxPolarAngle={Math.PI / 2}    // Limit the vertical rotation
            />
            <PresentationControls
                global
                rotation={[0.13, 0.1, 0]}
                polar={[-0.4, 0.2]}
                azimuth={[-1, 0.75]}
                config={{ mass: 2, tension: 400 }}
                snap={{ mass: 4, tension: 400 }}
            >
                <Float rotationIntensity={0.4}>
                    <rectAreaLight
                        width={2.5}
                        height={1.65}
                        intensity={65}
                        color='#EEE9E3'
                        rotation={[0.1, Math.PI, 0]}
                        position={[0, 0.55, -1.15]}
                    />
                    <Suspense fallback={<Html><div>Loading model...</div></Html>}>
                        <Model />
                    </Suspense>
                    <Suspense fallback={<Html><div>Loading text...</div></Html>}>
                        <TextComponent />
                    </Suspense>
                </Float>
            </PresentationControls>
            <ContactShadows
                position-y={-1.4}
                opacity={0.4}
                scale={5}
                blur={2.4}
            />
        </>
    );
}
